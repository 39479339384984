import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { Logger } from '../../../shared/utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number; // index of this tab
  selectedIndex: number; // currently selectedIndex
}

export const TabPanel = (props: TabPanelProps): ReactElement => {
  Logger.log('TabPanel()');
  const {
    children, index, selectedIndex,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={index !== selectedIndex}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tabpanel-${index}`}
    >
      {index === selectedIndex && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.defaultProps = {
  children: undefined,
};
