import styled from 'styled-components';

// Customer Search
export const CustomerSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomerSearchBackground = styled.div`
  width: 15.6rem;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 0.5rem;
  margin-right: 1rem;
`;

// This is for Customer Dashboard for customer users
export const CustomerUserContainer = styled.div`
  margin: 2rem;
`;

// This is for customer user
export const CustomerContentContainer = styled.div`
  width: 95vw;
  position: relative;
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 35vw 60vw;
  grid-template-rows: 6rem 20.5rem 21rem;
  grid-template-areas:
    "info     stats"
    "info     time-entries"
    "invoices time-entries";
  column-gap: 1.5rem;
  row-gap: 1.5rem;
`;

// This is for employees
export const ContentContainer = styled.div`
  width: 95vw;
  position: relative;
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 35vw 60vw;
  grid-template-rows: 6rem 12rem 14.5rem 21rem;
  grid-template-areas:
    "stats     chart"
    "info      chart"
    "info     time-entries"
    "invoices time-entries";
  column-gap: 1.5rem;
  row-gap: 1.5rem;
`;

export const GridTopSection = styled.div`
  grid-area: stats;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const GridInfoSection = styled.div`
  grid-area: info;
`;
export const GridInvoicesSection = styled.div`
  grid-area: invoices;
`;

export const GridChartSection = styled.div`
  grid-area: chart;
  display: flex;
`;

export const GridTimeEntriesSection = styled.div`
  grid-area: time-entries;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
