import React, { ReactElement } from 'react';
import { Logger } from '../../../shared/utils';
import { BillingInfo } from '../customer-dashboard-billing-info';
import { CustomerInvoiceList } from '../customer-dashboard-invoice-list';
import * as S from './customer-dashboard.styles';
import { BalanceCard, BudgetCard } from '../customer-dashboard-cards';
import { CustomerDashboardMenu } from '../customer-dashboard-menu';
import { CustomerTimeEntryList } from '../customer-dashboard-time-entry-list';
import { CustomerDialogComposite } from './customer-dialog-composite';
import { CustomerDashboardChart } from '../customer-dashboard-chart';

export const GpCustomerDashboardContent = (): ReactElement => {
  Logger.log('GpCustomerDashboardContent()');

  return (
    <S.ContentContainer>
      {/* Left column */}
      <BillingInfo />
      <CustomerInvoiceList />
      <S.GridTopSection>
        <S.CardsContainer>
          <BalanceCard />
          <BudgetCard />
        </S.CardsContainer>
        <CustomerDashboardMenu />
      </S.GridTopSection>
      {/* Right column */}
      <CustomerDashboardChart />
      <CustomerTimeEntryList height={496} />
      <CustomerDialogComposite />
    </S.ContentContainer>
  );
};
