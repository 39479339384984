import React, { ReactElement, useEffect } from 'react';
import { BillingInfo } from '../customer-dashboard-billing-info';
import * as S from './customer-dashboard.styles';
import { BalanceCard, BudgetCard } from '../customer-dashboard-cards';
import { CustomerTimeEntryList } from '../customer-dashboard-time-entry-list';
import { useAuthUser } from '../../auth';
import { CustomerInvoiceList } from '../customer-dashboard-invoice-list';
import { CustomerDetailProfile } from '../../customers';
import { useGetCustomerDetailQuery } from '../data';
import { dashboardCustomerChange } from './dashboard-customer-slice';
import { useAppDispatch } from '../../../app/hooks';

export const CustomerDashboard = (): ReactElement => {
  const dispatch = useAppDispatch();

  const { customerId } = useAuthUser() as CustomerDetailProfile;
  const customerRes = useGetCustomerDetailQuery(customerId);

  useEffect(() => {
    dispatch(dashboardCustomerChange(customerRes.data));
  }, [dispatch, customerRes]);

  return (
    <S.CustomerUserContainer>
      <S.CustomerContentContainer>
        <BillingInfo />
        <CustomerInvoiceList />
        <S.GridTopSection>
          <S.CardsContainer>
            <BalanceCard />
            <BudgetCard />
          </S.CardsContainer>
        </S.GridTopSection>
        <CustomerTimeEntryList />
      </S.CustomerContentContainer>
    </S.CustomerUserContainer>
  );
};

type TreeNode = { value: string }

const mapNode = <T extends TreeNode>(node: T, f: (value: string) => string): T => ({
  ...node,
  value: f(node.value),
});
