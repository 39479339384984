import React, { ReactElement } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import * as S from './card.styles';
import { formatDateForUi, Logger } from '../../../shared/utils';
import { useAppSelector } from '../../../app/hooks';
import { selectDashboardCustomerBudget } from '../customer-dashboard/dashboard-customer-slice';

export const BudgetCard = (): ReactElement => {
  const budget = useAppSelector(selectDashboardCustomerBudget);
  const getUpdatedByInfo = (): string => {
    const updatedBy = `${budget?.updatedBy?.firstName || ''} ${budget?.updatedBy?.lastName || ''}`;
    Logger.log(`BudgetCard budget: ${JSON.stringify(budget)}`);
    Logger.log(`BudgetCard updatedBy: ${JSON.stringify(budget?.updatedBy)}`);
    const date = formatDateForUi(new Date(budget?.updatedAt ?? 0));
    return `Updated by ${updatedBy} - ${date}`;
  };
  return (
    <S.Card>
      <S.CardTitle>Budget</S.CardTitle>
      <S.CardValue>
        {budget?.amount ?? 0}
        €
      </S.CardValue>
      <S.CardToolTip title={getUpdatedByInfo()}>
        <IconButton size="small">
          <InfoOutlined />
        </IconButton>
      </S.CardToolTip>
    </S.Card>
  );
};
