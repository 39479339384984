import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { addListener } from '@reduxjs/toolkit';
import * as S from './customer-dashboard.styles';
import { BillingInfo } from '../customer-dashboard-billing-info';
import { BalanceCard, BudgetCard } from '../customer-dashboard-cards';
import { CustomerTimeEntryList } from '../customer-dashboard-time-entry-list';
import { CustomerInvoiceList } from '../customer-dashboard-invoice-list';
import { useAppDispatch } from '../../../app/hooks';
import { dialogClose, DialogClosePayload, DialogResult } from '../../core/dialog/dialog-slice';
import { SEND_INVOICE_DIALOG_TAG } from '../customer-dashboard-checkout/send-invoice-dialog';
import {
  CHARGE_CREDIT_CARD_DIALOG_TAG,
} from '../customer-dashboard-checkout/charge-credit-card-dialog';
import { CustomerDashboardMenu } from '../customer-dashboard-menu';
import { CustomerDialogComposite } from './customer-dialog-composite';
import { BUDGET_DIALOG_TAG } from '../../customer-budget/ui/customer-budget-dialog';
import { useCustomerDetail } from '../data';

export const GpCustomerDashboardOld = (): ReactElement => {
  // TODO dashboard can we remove this already?
  const dispatch = useAppDispatch();
  // TODO dashboard remove lines below about loading customer
  const params = useParams();
  const { customerId } = JSON.parse(JSON.stringify(params));
  const customerState = useCustomerDetail(customerId);

  const isRefreshNeeded = (payload: DialogClosePayload) => (
    payload.result === DialogResult.SUCCESS
    && (
      payload.tag === SEND_INVOICE_DIALOG_TAG
      || payload.tag === CHARGE_CREDIT_CARD_DIALOG_TAG
      || payload.tag === BUDGET_DIALOG_TAG
    ));

  useEffect(() => {
    const unsubscribe = dispatch(addListener({
      actionCreator: dialogClose,
      effect: (action, listenerApi) => {
        if (isRefreshNeeded(action.payload)) {
          window.location.reload();
        }
      },
    }));
    return unsubscribe;
  }, []);

  if (customerState.response === null) {
    return <div />;
  }
  // TODO dashboard,remove prop drilling use redux
  return (
    <S.ContentContainer>
      <BillingInfo />
      <CustomerInvoiceList />
      <S.GridTopSection>
        <S.CardsContainer>
          <BalanceCard />
          <BudgetCard />
        </S.CardsContainer>
        <CustomerDashboardMenu />
      </S.GridTopSection>
      <CustomerTimeEntryList />
      <CustomerDialogComposite />
    </S.ContentContainer>
  );
};
