import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import {
  ListItem, Paper, TextField, Tooltip,
} from '@mui/material';

export const GrowthPartnerSearchBackground = styled.div`
  width: 15.6rem;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 0.5rem;
  margin-right: 1rem;
`;

// Shared
export const PaperContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem 0;
  width: 40vw;
`;

export const SmallHeader = styled(Typography)`
  color: #8c8c8c;
  font-size: 1rem;
  margin: 0.8rem auto 0.8rem 0.8rem;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;
`;

export const DescriptionText = styled(Typography)`
  font-size: 1rem;
  color: #555555;
  margin-right: 0.5rem;
`;

export const ValueText = styled(Typography)`
  font-size: 1.2rem;
  color: #555555;
  margin-right: 0.5rem;
  font-weight: 500;
`;

export const DatePicker = styled(TextField)`
  width: 10rem;
  height: 1rem;
  margin: 0.5rem;
`;

// Page level styling
export const DashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

// Top
export const DashboardTop = styled.div`
  align-self: start;
  margin-bottom: 1rem;
`;

// Time Stats
export const TimeStatsTop = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
  justify-content: end;
`;

export const TimeStatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, [column-start] 1fr [column-end column-start]);
  grid-template-rows: repeat(3, [row-start] 1fr [row-end row-start]);
  justify-items: center;
  align-items: start;
`;

export const TimeBalanceDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const TimeBalanceToolTip = styled(Tooltip)`
  margin: 0;
  padding: 0;
`;

// Holidays
export const HolidayStatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, [column-start] 1fr [column-end column-start]);
  grid-template-rows: [row-start] 1fr [row-end row-start] 2.5fr [row-end];
  justify-items: center;
  align-items: start;
`;

export const HolidaysListContainer = styled.div`
  width: 40vw;
  margin: 0 auto;
`;

export const HolidaysListItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ListItemLeft = styled(Typography)`
  margin-left: 1rem;
  margin-right: 1rem;
  width: 45%;
`;

export const ListItemMiddle = styled(Typography)`
  width: 35%;
`;

export const ListItemRight = styled(Typography)`
  margin-left: 1rem;
  margin-right: 1rem;
  width: 10%;
`;
