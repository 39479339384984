import React, { ReactElement, useEffect } from 'react';
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';
import { ThemeProvider as SThemeProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Provider } from 'react-redux';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GaaslyRoutes } from '../features/routes';
import {
  ContractPage,
  EmailConfirmationPage,
  GpCustomerPage,
  InvitationRegisterPage,
  LoginPage,
  PaymentMethodPage,
  RegisterEmailSentPage,
  RegisterPage,
  ResetPasswordConfirmPage,
  ResetPasswordPage,
  TimeTrackingPage,
} from '../pages';
import { NavBar } from '../features/nav-bar';
import {
  GoogleSignIn, loadAuthUser, logout, useAuthUser,
} from '../features/auth';
import { Logger } from '../shared/utils';
import { store } from './store';
import { theme } from '../features/theme';
import { CustomerOnboarding, GrowthPartnerOnboarding } from '../features/onboarding';
import { useIsNavBar } from '../features/nav-bar/use-is-nav-bar';
import { CustomerProfilePage } from '../features/profile/customer-profile/customer-profile-page';
import { RequireNoAuth } from '../features/routes/require-no-auth';
import { RequireAuthAndForcedRoutes } from '../features/routes/require-auth-and-forced-routes';
import { RequireAuth } from '../features/routes/require-auth';
import { TermsOfUse } from '../features/terms';
import { PrivacyPolicy } from '../features/terms/privacy-policy';
import { CustomerDashboard } from '../features/dashboard';
import { Dashboard } from '../features/dashboard/main';

const googleOAuthClientId: string = process.env.REACT_APP_GOOGLE_OAUTH_CLIEND_ID || '';

// Make sure to call `loadStripe` outside of a component's render to avoid recreating
const stripeApiKey: string = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
const stripePromise = loadStripe(stripeApiKey);

function initSentry() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  if (environment === 'DEVELOPMENT') return;

  const tracesSampleRate = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? 0.1 : 1.0;
  Sentry.init({
    dsn: 'https://6ffd9f2401374578902210ec9396ec79@o973649.ingest.sentry.io/5931835',
    integrations: [browserTracingIntegration()],
    environment,
    tracesSampleRate,
  });
}

function Content(): ReactElement {
  const isVisible = useIsNavBar();
  const topMargin = isVisible ? 70 : 0;

  return (
    <div style={{ marginTop: topMargin }}>
      <Routes>
        {/* Open routes that everyone can access whether signed in */}
        <Route
          path="/"
          element={(
            <Navigate
              to={GaaslyRoutes.login}
              replace
            />
          )}
        />
        <Route
          path={GaaslyRoutes.termsOfUse}
          element={(
            <TermsOfUse />
          )}
        />
        <Route
          path={GaaslyRoutes.privacyPolicy}
          element={(
            <PrivacyPolicy />
          )}
        />
        <Route
          path={GaaslyRoutes.resetPasswordConfirm}
          element={(
            <ResetPasswordConfirmPage />
          )}
        />
        <Route
          path={GaaslyRoutes.confirmEmail}
          element={(
            <EmailConfirmationPage />
          )}
        />

        {/* Routes that should be only accessed by non-authenticated users */}
        <Route
          path={GaaslyRoutes.login}
          element={(
            <RequireNoAuth>
              <LoginPage />
            </RequireNoAuth>
          )}
        />
        <Route
          path={GaaslyRoutes.register}
          element={(
            <RequireNoAuth>
              <RegisterPage />
            </RequireNoAuth>
          )}
        />
        <Route
          path={GaaslyRoutes.growthPartnerSignIn}
          element={(
            <RequireNoAuth>
              <GoogleSignIn />
            </RequireNoAuth>
          )}
        />
        <Route
          path={GaaslyRoutes.invitationRegister}
          element={(
            <RequireNoAuth>
              <InvitationRegisterPage />
            </RequireNoAuth>
          )}
        />
        <Route
          path={GaaslyRoutes.confirmEmailSent}
          element={(
            <RequireNoAuth>
              <RegisterEmailSentPage />
            </RequireNoAuth>
          )}
        />
        <Route
          path={GaaslyRoutes.resetPassword}
          element={(
            <RequireNoAuth>
              <ResetPasswordPage />
            </RequireNoAuth>
          )}
        />

        {/* Routes that require authentication and use logic to force
            things like onboarding & contract signed */}
        {/* Customer routes */}
        <Route
          path={GaaslyRoutes.customerDashboard}
          element={(
            <RequireAuthAndForcedRoutes>
              <CustomerDashboard />
            </RequireAuthAndForcedRoutes>
          )}
        />
        <Route
          path={GaaslyRoutes.customerProfile}
          element={(
            <RequireAuthAndForcedRoutes>
              <CustomerProfilePage />
            </RequireAuthAndForcedRoutes>
          )}
        />
        <Route
          path={GaaslyRoutes.paymentMethod}
          element={(
            <RequireAuthAndForcedRoutes>
              <PaymentMethodPage />
            </RequireAuthAndForcedRoutes>
          )}
        />
        {/* Growth Partner routes */}
        <Route
          path={GaaslyRoutes.timeTracking}
          element={(
            <RequireAuthAndForcedRoutes>
              <TimeTrackingPage />
            </RequireAuthAndForcedRoutes>
          )}
        />
        <Route
          path={GaaslyRoutes.growthPartnerDashboardRoute}
          element={(
            <RequireAuthAndForcedRoutes>
              <Dashboard />
            </RequireAuthAndForcedRoutes>
          )}
        />
        <Route
          path={GaaslyRoutes.customerDetail}
          element={(
            <RequireAuthAndForcedRoutes>
              <GpCustomerPage />
            </RequireAuthAndForcedRoutes>
          )}
        />

        {/* Forced routes should only require the authentication */}
        <Route
          path={GaaslyRoutes.userContracts}
          element={(
            <RequireAuth>
              <ContractPage />
            </RequireAuth>
          )}
        />
        <Route
          path={GaaslyRoutes.growthPartnerOnboarding}
          element={(
            <RequireAuth>
              <GrowthPartnerOnboarding />
            </RequireAuth>
          )}
        />
        <Route
          path={GaaslyRoutes.customerOnboarding}
          element={(
            <RequireAuth>
              <CustomerOnboarding />
            </RequireAuth>
          )}
        />
      </Routes>
    </div>
  );
}

function App(): ReactElement {
  initSentry();
  useEffect(() => {
    loadAuthUser().then((response) => {
      Logger.log(`App loadAuthUser - response ${JSON.stringify(response)}`);
    });
  }, []);

  const authUser = useAuthUser();
  useEffect(() => {
    if (!authUser) {
      logout();
    }
  }, [authUser]);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SThemeProvider theme={theme}>
            <GoogleOAuthProvider clientId={googleOAuthClientId}>
              <Elements stripe={stripePromise}>
                <CssBaseline />
                <Router>
                  <NavBar />
                  <Content />
                </Router>
              </Elements>
            </GoogleOAuthProvider>
          </SThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>

  );
}

export default App;
