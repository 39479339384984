import { useEffect } from 'react';
import { addListener } from '@reduxjs/toolkit';
import { positiveAction, PositiveActionPayload } from '../../core/dialog/dialog-slice';
import { useAppDispatch } from '../../../app/hooks';
import { CHARGE_CREDIT_CARD_DIALOG_TAG } from './charge-credit-card-dialog';
import { SEND_INVOICE_DIALOG_TAG } from './send-invoice-dialog';

export const useCheckout = (
  customerId: string,
  checkout: (customerId: string) => void,
) => {
  const dispatch = useAppDispatch();

  const isCheckoutAction = (payload: PositiveActionPayload): boolean => (
    CHARGE_CREDIT_CARD_DIALOG_TAG === payload.tag
    || SEND_INVOICE_DIALOG_TAG === payload.tag
  );

  useEffect(() => {
    const unsubscribe = dispatch(addListener({
      actionCreator: positiveAction,
      effect: (action) => {
        if (isCheckoutAction(action.payload)) {
          checkout(customerId);
        }
      },
    }));
    return unsubscribe;
  }, []);
};
