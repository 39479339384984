import styled from 'styled-components';
import { Paper } from '@mui/material';
import { H2, H3 } from '../../theme/text.styles';

export * from '../../theme/text.styles';

export { GridInfoSection } from '../customer-dashboard/customer-dashboard.styles';

export const Container = styled(Paper)`
  height: 100%;
  margin: 0 auto;
  padding: 1.5rem;
`;

export const InnerContainer = styled(Paper)`
  background-color: #F6F7F8;
  padding: 1rem;
`;

export const SectionHeaderH1 = styled(H2)`
  margin-bottom: 0.5rem;
`;

export const SectionHeaderH2 = styled(H3)`
  margin: 1.5rem 0 0.5rem 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.2rem;
`;

export const RowField = styled.div`
  width: 7rem;
`;

export const MultiRowBox = styled.div`
  display: flex;
  flex-direction: column;
`;
