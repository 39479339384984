import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logger } from '../../../shared/utils';
import { CustomerSelectSearch } from '../../../shared/ui';
import { CustomerListProfile } from '../../customers';
import { GpCustomerDashboardContent } from './gp-customer-dashboard-content';
import * as S from './customer-dashboard.styles';
import { useRefresh } from './use-refresh';
import { useCustomer } from './use-customer';

export const GpCustomerDashboard = (): ReactElement => {
  Logger.log('CustomerDashboard()');

  const navigate = useNavigate();
  useRefresh();
  const [customerRes, setSelectedCustomerId] = useCustomer();

  const setCustomer = (customer: CustomerListProfile) => {
    setSelectedCustomerId(customer.customerId);
    navigate(`/dashboard/customers/${customer.customerId}`, { replace: true });
  };

  return (
    <>
      <S.CustomerSearchContainer>
        <S.CustomerSearchBackground>
          <CustomerSelectSearch
            defaultCustomer={customerRes.data as CustomerListProfile}
            setCustomer={setCustomer}
            size="small"
            autoSelectFirst
          />
        </S.CustomerSearchBackground>
      </S.CustomerSearchContainer>
      <GpCustomerDashboardContent />
    </>
  );
};
