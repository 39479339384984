import { Tab as MuiTab } from '@mui/material';
import React, { ReactElement } from 'react';
import { TabPanel } from './tab-panel';
import { GpCustomerDashboard } from '../customer-dashboard';
import { useTabs } from '../data/use-urls';
import * as S from './styles';
import { GrowthPartnerDashboard } from '../gp-dashboard';

export const Dashboard = (): ReactElement => {
  const [currentTab, tabs, changeTab] = useTabs();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    changeTab(newValue);
  };

  return (
    <>
      <S.Tabs
        value={currentTab?.index}
        onChange={handleChange}
      >
        {
          tabs.map((tab) => (
            <MuiTab key={tab.index} value={tab.index} label={tab.label} />
          ))
        }
      </S.Tabs>

      <TabPanel index={0} selectedIndex={currentTab.index}>
        <GrowthPartnerDashboard />
      </TabPanel>
      <TabPanel index={1} selectedIndex={currentTab.index}>
        <GpCustomerDashboard />
      </TabPanel>
    </>
  );
};
