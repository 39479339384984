import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerDetailProfile } from '../../customers';
import { RootState } from '../../../app/store';

interface DashboardCustomerState {
  customer: CustomerDetailProfile | undefined
}

const initialState: DashboardCustomerState = {
  customer: undefined,
};

export const dashboardCustomerSlice = createSlice({
  name: 'dashboardCustomerSlice',
  initialState,
  reducers: {
    dashboardCustomerChange: (state, action: PayloadAction<CustomerDetailProfile | undefined>) => {
      state.customer = action.payload;
    },
  },
});

export const {
  dashboardCustomerChange,
} = dashboardCustomerSlice.actions;

export const selectDashboardCustomer = (
  state: RootState,
) => state.dashboardCustomer.customer;

export const selectDashboardCustomerId = (
  state: RootState,
) => state.dashboardCustomer.customer?.customerId;

export const selectDashboardCustomerBalance = (
  state: RootState,
) => state.dashboardCustomer.customer?.openBalance;

export const selectDashboardCustomerBudget = (
  state: RootState,
) => state.dashboardCustomer.customer?.currentBudget;

export const selectDashboardCustomerPaymentMethod = (
  state: RootState,
) => state.dashboardCustomer.customer?.paymentMethod;

export const selectDashboardCustomerHasCreditCard = (
  state: RootState,
) => state.dashboardCustomer.customer?.hasCreditCard;

export default dashboardCustomerSlice.reducer;
