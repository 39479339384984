import { MenuItem } from '@mui/material';
import React, { ReactNode } from 'react';
import { PaymentMethod } from '../../payments/data/types';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  creditCardChargeDialogOpen,
  sendInvoiceDialogOpen,
} from '../customer-dashboard-checkout/customer-checkout-slice';
import { budgetDialogOpen } from '../../customer-budget';
import { placeHolderBudget } from '../data';
import {
  selectDashboardCustomerBalance,
  selectDashboardCustomerBudget,
  selectDashboardCustomerHasCreditCard,
  selectDashboardCustomerPaymentMethod,
} from '../customer-dashboard/dashboard-customer-slice';

/**
 *  Conditionally returns list of menu items based on which ones should be available for the
 *  customer. It can also be empty list.
 */
export const useMenuItems = (closeMenu: () => void): ReactNode[] => {
  const menuItems: ReactNode[] = [
    ...useBudgetItem(closeMenu),
    ...useSendInvoiceItem(closeMenu),
    ...useChargeCreditCardItem(closeMenu),
  ];
  return menuItems;
};

const useBudgetItem = (closeMenu: () => void): ReactNode[] => {
  const budget = useAppSelector(selectDashboardCustomerBudget) ?? placeHolderBudget;
  const dispatch = useAppDispatch();
  if (budget.amount <= 0) {
    const onClick = () => {
      dispatch(budgetDialogOpen());
      closeMenu();
    };
    return [<MenuItem onClick={onClick}>Add Budget</MenuItem>];
  }
  return [];
};

const useSendInvoiceItem = (
  closeMenu: () => void,
): ReactNode[] => {
  const balance = useAppSelector(selectDashboardCustomerBalance) ?? 0;
  const paymentMethod = useAppSelector(selectDashboardCustomerPaymentMethod)
    ?? PaymentMethod.INVOICE;
  const dispatch = useAppDispatch();
  if (balance > 0 && PaymentMethod.INVOICE === paymentMethod) {
    const onClick = () => {
      dispatch(sendInvoiceDialogOpen());
      closeMenu();
    };
    return [<MenuItem onClick={onClick}>Send Invoice</MenuItem>];
  }
  return [];
};

const useChargeCreditCardItem = (
  closeMenu: () => void,
): ReactNode[] => {
  const balance = useAppSelector(selectDashboardCustomerBalance) ?? 0;
  const paymentMethod = useAppSelector(selectDashboardCustomerPaymentMethod)
    ?? PaymentMethod.INVOICE;
  const hasCreditCard = useAppSelector(selectDashboardCustomerHasCreditCard);

  const dispatch = useAppDispatch();
  if (balance > 0 && PaymentMethod.CARD === paymentMethod && hasCreditCard) {
    const onClick = () => {
      dispatch(creditCardChargeDialogOpen());
      closeMenu();
    };
    return [<MenuItem onClick={onClick}>Charge Credit Card</MenuItem>];
  }
  return [];
};
