import styled from 'styled-components';
import { Paper } from '@mui/material';
import { H3 } from '../../theme/text.styles';

export { GridInvoicesSection } from '../customer-dashboard/customer-dashboard.styles';

export const Container = styled(Paper)`
  padding: 1rem;
  height: 100%;
`;

export const SectionHeader = styled(H3)`
`;
