import React, { ReactElement } from 'react';
import { CustomerDetailProfile } from '../../customers';
import { Logger } from '../../../shared/utils';
import { Budget } from '../../dashboard/data/types';

interface CustomerBudgetDialogDescriptionProps {
  customer: CustomerDetailProfile | undefined,
  previousBudget: Budget | undefined,
}

export const CustomerBudgetDialogDescription: React.FC<CustomerBudgetDialogDescriptionProps> = (
  { customer, previousBudget },
): ReactElement => {
  Logger.log('asdf');

  return (
    <div>
      Last month&apos;s budget for
      {' '}
      {customer?.companyName ?? ''}
      {' '}
      was
      {' '}
      {previousBudget?.amount ?? 0}
      €
      {' '}
      , please confirm the budget for the current
      month below. If you are not sure, press later and discuss with the team.
    </div>
  );
};
