import React, { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import * as S from './card.styles';
import { roundToTwoDecimals } from '../../../shared/utils';
import { useAppSelector } from '../../../app/hooks';
import { selectDashboardCustomerBalance } from '../customer-dashboard/dashboard-customer-slice';

export const BalanceCard = (): ReactElement => {
  const balance = useAppSelector(selectDashboardCustomerBalance) ?? 0;
  const roundedBalance = roundToTwoDecimals(balance);
  return (
    <S.Card>
      <S.CardTitle>Balance</S.CardTitle>
      <S.CardValue>
        {roundedBalance}
        €
      </S.CardValue>
      <S.CardToolTip title="Balance is calculated with 0% VAT.">
        <IconButton size="small">
          <InfoOutlined />
        </IconButton>
      </S.CardToolTip>
    </S.Card>
  );
};
