import React, { ReactElement } from 'react';
import { Menu } from '@mui/material';
import { MenuButton } from './menu-button';
import { useMenuItems } from './use-menu-items';

export const CustomerDashboardMenu: React.FC = (): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);
  const menuItems = useMenuItems(closeMenu);

  switch (menuItems.length) {
    case 0:
      return (<div />);
    default:
      return (
        <>
          <MenuButton onClick={openMenu} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {menuItems}
          </Menu>
        </>
      );
  }
};
