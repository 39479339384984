import { useEffect } from 'react';
import { addListener } from '@reduxjs/toolkit';
import { Logger } from '../../../shared/utils';
import { useAppDispatch } from '../../../app/hooks';
import { positiveAction, PositiveActionPayload } from '../../core/dialog/dialog-slice';
import { CreateBudgetArgs, useGetBudgetsQuery } from './budget-api-slice';
import { BUDGET_DIALOG_TAG } from '../ui/customer-budget-dialog';
import { getMonthYear } from '../../core/utils/date-utils';

import { Budget } from '../../dashboard/data/types';

export const useCreateBudget = (
  customerId: string,
  budget: number,
  createBudget: ({ customerId, budget }: CreateBudgetArgs) => void,
) => {
  Logger.log(`useCreateBudget ${customerId}, ${budget}`);
  const dispatch = useAppDispatch();

  const isCreateBudgetAction = (
    payload: PositiveActionPayload,
  ): boolean => BUDGET_DIALOG_TAG === payload.tag;

  useEffect(() => {
    const unsubscribe = dispatch(addListener({
      actionCreator: positiveAction,
      effect: (action) => {
        if (isCreateBudgetAction(action.payload)) {
          Logger.log(`CreateBudget() customerId ${customerId}, budget ${budget}`);
          createBudget({ customerId, budget });
        }
      },
    }));
    return unsubscribe;
  }, [dispatch, customerId, budget, createBudget]);
};

/**
 * Returns Customer's budget. By default for current month, but accepts delta parameter.
 * @param customerId
 * @param delta: number for delta on which month to return
 */
export const useCustomerBudget = (customerId: string, delta = 0):
  [Budget | undefined, ReturnType<typeof useGetBudgetsQuery>] => {
  const budgetsRes = useGetBudgetsQuery({ customerId, monthCount: 2 });
  Logger.log(`budgets ${JSON.stringify(budgetsRes)}`);
  const monthYear = getMonthYear(delta);
  const budget = budgetsRes.data?.find(
    (b) => b.monthYear === monthYear,
  );

  return [budget, budgetsRes];
};
