import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from './index';

export const useGetCurrentTab = (tabsMap: Map<string, Tab>): Tab => {
  const params = useParams();
  const { tab: tabParam } = JSON.parse(JSON.stringify(params));
  return tabsMap.get(tabParam) ?? tabsMap.values().next().value;
};

export const useTabs = () => {
  const tabsMap: Map<string, Tab> = new Map([
    ['growth-partners', { index: 0, label: 'Growth Partners' }],
    ['customers', { index: 1, label: 'Customers' }],
  ]);

  const currentTab = useGetCurrentTab(tabsMap);

  const [tab, setTab] = useState<Tab>(currentTab);

  const changeTab = (newTabIndex: number) => {
    tabsMap.forEach((t, key) => {
      if (newTabIndex === t.index) {
        setTab(t);
        window.history.replaceState(null, '', `/dashboard/${key}`);
      }
    });
  };

  return [tab, Array.from(tabsMap.values()), changeTab] as const;
};
