import React, { ReactElement } from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TimeEntry } from '../../time-tracking/data';
import { CustomerTimeEntryListItem } from './customer-time-entry-list-item';
import * as S from './customer-time-entry-list.styles';
import { CustomerTimeEntryListHeader } from './customer-time-entry-list-header';
import { useGetCustomerTimeEntriesQuery } from '../data';
import { useAppSelector } from '../../../app/hooks';
import { selectDashboardCustomerId } from '../customer-dashboard/dashboard-customer-slice';

interface CustomerTimeEntryListProps {
  height?: number
}

export const CustomerTimeEntryList: React.FC<CustomerTimeEntryListProps> = (
  { height = 576 },
): ReactElement => {
  const customerId = useAppSelector(selectDashboardCustomerId) ?? '';
  const res = useGetCustomerTimeEntriesQuery({ customerId });
  const {
    data: { count, results } = { count: 0, results: [] },
  } = res;

  // We want to show empty list while loading another customer
  const entries = res.isFetching ? [] : results || [];

  return (
    <S.GridTimeEntriesSection>
      <S.Container>
        <S.SectionHeader>Time Entries</S.SectionHeader>
        <AutoSizer>
          {({ width = 0 }) => (
            <>
              <CustomerTimeEntryListHeader listWidth={width} />
              <FixedSizeList<TimeEntry[]>
                itemSize={48}
                height={height}
                width={width}
                itemCount={entries.length}
                overscanCount={5}
                itemData={entries}
              >
                {CustomerTimeEntryListItem}
              </FixedSizeList>
            </>
          )}
        </AutoSizer>
      </S.Container>
    </S.GridTimeEntriesSection>
  );
};

CustomerTimeEntryList.defaultProps = {
  height: 576,
};
