import React, { ReactElement } from 'react';
import { StatefulDialog } from '../../core/dialog';
import { DialogStatus } from '../../core/dialog/dialog-slice';
import { useCheckoutCustomerMutation } from '../data/customers-api-slice';
import { useInitDialog, useUpdateDialogStatus } from '../../core/dialog/use-dialog';
import { useCheckout } from './use-checkout';
import { useAppSelector } from '../../../app/hooks';
import { selectDashboardCustomerId } from '../customer-dashboard/dashboard-customer-slice';

export const SEND_INVOICE_DIALOG_TAG = 'send-invoice';

export const SendInvoiceDialog = (): ReactElement => {
  const customerId = useAppSelector(selectDashboardCustomerId) ?? '';
  const [checkout, result] = useCheckoutCustomerMutation();

  const sendInvoiceDialogDetails = {
    tag: SEND_INVOICE_DIALOG_TAG,
    isOpen: true,
    status: DialogStatus.INITIAL,
    title: 'Send Invoice',
    loadingText: 'Preparing invoice...',
    errorText: 'Invoice sending failed. Please try again later.',
    successText: 'Invoice sent successfully.',
    positiveActionText: 'Send',
    negativeActionText: 'Cancel',
  };

  useCheckout(customerId, checkout);
  useUpdateDialogStatus(result);
  useInitDialog(sendInvoiceDialogDetails, SEND_INVOICE_DIALOG_TAG);

  return (
    <StatefulDialog>
      Create and send invoice to the Customer. Note that all un-invoiced time entries will be
      automatically included in the invoice and once the time entries are invoiced, they become
      immutable to maintain accurate billing records.
    </StatefulDialog>
  );
};
