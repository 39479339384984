import React, { ReactElement } from 'react';
import { BarChart } from '@mui/x-charts';
import * as S from '../customer-dashboard/customer-dashboard.styles';
import { useGetCustomerStatsQuery } from '../data';
import { useAppSelector } from '../../../app/hooks';
import { selectDashboardCustomerId } from '../customer-dashboard/dashboard-customer-slice';

const valueFormatter = (value: number | null) => `€${value}`;

export const CustomerDashboardChart = (): ReactElement => {
  const customerId = useAppSelector(selectDashboardCustomerId);
  const { data } = useGetCustomerStatsQuery(customerId ?? '');
  const stats = data ?? [];
  const reversedStats = [...stats].reverse();

  return (
    <S.GridChartSection>
      <BarChart
        colors={['#2d89fb', '#0ca9a6']}
        dataset={reversedStats}
        series={[
          { dataKey: 'budget', label: 'Budget', valueFormatter },
          { dataKey: 'revenue', label: 'Revenue', valueFormatter },
        ]}
        height={330}
        yAxis={[{ label: 'EUR' }]}
        xAxis={[{ dataKey: 'month', scaleType: 'band' }]}
        margin={{
          top: 10, bottom: 30, left: 40, right: 10,
        }}
      />
    </S.GridChartSection>
  );
};
