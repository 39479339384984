import { useEffect, useState } from 'react';
import { CustomerListProfile } from '../customers';

export const useAutoSelectFirst = (
  autoSelectFirst: boolean,
  customers: CustomerListProfile[],
  setSelectedCustomer: (customer: CustomerListProfile) => void,
  defaultCustomer: CustomerListProfile | null | undefined,
): void => {
  const [isFirstCustomerSelected, setIsFirstCustomerSelected] = useState(false);
  const isSelectFirst = () => autoSelectFirst
    && !isFirstCustomerSelected
    && !defaultCustomer
    && customers.length > 0;

  useEffect(() => {
    // Select first customer in list
    if (isSelectFirst()) {
      setSelectedCustomer(customers[0]);
      setIsFirstCustomerSelected(true);
    }
  }, [
    defaultCustomer, customers, setSelectedCustomer,
    isFirstCustomerSelected, setIsFirstCustomerSelected]);
};
