import { roundToTwoDecimals } from '../../../shared/utils';
import { placeholderUser, UserProfile } from '../../../shared/models';

// UI
export interface Tab {
  index: number;
  label: string;
}

export enum InvoiceStatus {
  OPEN = 'open',
  PAID = 'paid',
  OVERDUE = 'overdue',
}

export type ApiInvoice = {
  createdDate: Date,
  dueDate: Date | null,
  invoiceNumber: string,
  status: InvoiceStatus,
  invoicePdf: string,
  total: number,
  currency: string,
}

export class Invoice {
  private createdDate: Date;

  private dueDate: Date | null;

  private invoiceNumber: string;

  private status: InvoiceStatus;

  private invoicePdfUrl: string;

  private amount: number;

  private currency: string;

  constructor(apiInvoice: ApiInvoice) {
    this.createdDate = new Date(apiInvoice.createdDate);
    if (apiInvoice.dueDate === null) {
      this.dueDate = null;
    } else {
      this.dueDate = new Date(apiInvoice.dueDate);
    }
    this.invoiceNumber = apiInvoice.invoiceNumber;
    this.status = apiInvoice.status;
    this.invoicePdfUrl = apiInvoice.invoicePdf;
    this.amount = apiInvoice.total;
    this.currency = apiInvoice.currency;
  }

  public getAmount(): number {
    return this.amount;
  }

  public getCurrency(): string {
    return this.currency;
  }

  public getCreated(): Date {
    return this.createdDate;
  }

  public getDueDate(): Date | null {
    return this.dueDate;
  }

  public getInvoiceNumber(): string {
    return this.invoiceNumber;
  }

  public getFormattedAmount(): string {
    return `${roundToTwoDecimals(this.amount)} ${this.currency}`;
  }

  public getFormattedCreatedDate(): string {
    return this.createdDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  public getFormattedDueDate(): string {
    if (this.dueDate === null) return '';
    return this.dueDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  public getStatus(): InvoiceStatus {
    return this.status;
  }

  public getInvoicePdfUrl(): string {
    return this.invoicePdfUrl;
  }
}

export const placeHolderBudget = {
  amount: 0,
  monthYear: '12-2024',
  updatedAt: '1.1.1970',
  updatedBy: placeholderUser,
  customerId: '',
};

export type Budget = {
  amount: number,
  monthYear: string,
  updatedAt: string,
  updatedBy: UserProfile
  customerId: string,
}

export type CustomerStats = {
  month: string,
  budget: number,
  revenue: number,
}

/* GrowthPartner related */

export type HolidayEntry = {
  uuid: string,
  timeSpentMinutes: number,
  pricePerHour: number,
  workDoneDate: Date,
  lastModified: Date,
  description: string,
  // GrowthPartner related info
  growthPartnerId: string,
  firstName: string,
  lastName: string,
  email: string,
}

export type HolidayStats = {
  accumulatedHolidays: number,
  usedHolidays: number,
  holidayBalance: number,
  upcomingHolidays: HolidayEntry[],
}

export type TimeStats = {
  allHours: number,
  invoicedHours: number,
  internalHours: number,
  holidayHours: number,
  revenue: number,
  averageHourlyPrice: number,
  timeBalance?: number,
}
