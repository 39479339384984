import React, { ReactElement } from 'react';
import * as S from '../../core';
import { InvoiceStatus } from '../data/types';

export const InvoiceStatusTag = (
  { status }: { status: InvoiceStatus },
): ReactElement => {
  const statusString = status.charAt(0).toUpperCase() + status.slice(1);
  if (status === InvoiceStatus.PAID) {
    return <S.GreenTag>{statusString}</S.GreenTag>;
  }
  if (status === InvoiceStatus.OVERDUE) {
    return <S.RedTag>{statusString}</S.RedTag>;
  }
  return <S.BlueTag>{statusString}</S.BlueTag>;
};
