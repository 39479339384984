import { useEffect, useState } from 'react';
import { fetchGrowthPartnerStats, fetchHolidays } from './gp-dashboard-api';
import { StatefulResponse } from '../../../shared/models';
import { HolidayStats, TimeStats } from './types';

export const useHolidays = (
  growthPartnerFilter: string,
): StatefulResponse<HolidayStats> => {
  const [state, setState] = useState<StatefulResponse<HolidayStats>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ ...state, isLoading: true, response: null });
    fetchHolidays(growthPartnerFilter).then((response) => {
      setState({ isLoading: false, response });
    });
  }, [growthPartnerFilter]);

  return state;
};

export const useStats = (
  growthPartnerFilter: string,
  startDate: string,
  endDate: string,
): StatefulResponse<TimeStats> => {
  const [state, setState] = useState<StatefulResponse<TimeStats>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ ...state, isLoading: true, response: null });
    fetchGrowthPartnerStats(growthPartnerFilter, startDate, endDate).then((response) => {
      setState({ isLoading: false, response });
    });
  }, [growthPartnerFilter, startDate, endDate]);

  return state;
};
