import { placeholderUser, UserProfile } from '../../../shared/models';
import { PaymentMethod } from '../../payments/data/types';
import { Budget } from '../../dashboard/data/types';

export const placeHolderBudget = {
  amount: 0,
  monthYear: '',
  updatedAt: '',
  customerId: '',
  updatedBy: placeholderUser,
};

export interface CustomerDetailProfile extends UserProfile {
  customerId: string,
  invoicingEmail: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  postalCode: string,
  country: string,
  isCompany: boolean,
  companyName: string,
  vatId: string,
  hasCreditCard: boolean,
  hourlyPriceEur: number,
  openBalance: number,
  paymentMethod: PaymentMethod,
  currentBudget: Budget,
  additionalBillingInfo: string,
}

export const placeholderCustomer: CustomerDetailProfile = {
  ...placeholderUser,
  customerId: '',
  invoicingEmail: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  country: '',
  isCompany: true,
  companyName: '',
  vatId: '',
  hasCreditCard: false,
  hourlyPriceEur: 80.0,
  openBalance: 0.0,
  emailChanged: false,
  paymentMethod: PaymentMethod.CARD,
  currentBudget: placeHolderBudget,
  additionalBillingInfo: '',
};

export type CustomerListProfile = {
  userId: string,
  customerId: string,
  email: string,
  firstName: string,
  lastName: string,
  companyName: string,
  hourlyPriceEur: number,
  hasCreditCard: boolean,
  paymentMethod: PaymentMethod,
}

export const placeholderCustomerListProfile: CustomerListProfile = {
  userId: '',
  customerId: '',
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  hourlyPriceEur: 80,
  hasCreditCard: false,
  paymentMethod: PaymentMethod.INVOICE,
};
