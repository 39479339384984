import React, { ReactElement, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { GaaslyError, StatefulResponse } from '../../shared/models';
import * as S from '../theme/styles';
import { CustomerListProfile } from '../customers';
import { useCustomerList } from '../customers/data';
import { PaymentMethod } from '../payments/data/types';
import { FieldSize } from './types';
import { Logger } from '../../shared/utils';
import { useAutoSelectFirst } from './use-auto-select-first';

export const CustomerSelectSearch = (
  {
    errors, defaultCustomer, setCustomer, size = 'medium', autoSelectFirst = false,
  }: {
    errors?: GaaslyError | null,
    defaultCustomer?: CustomerListProfile | null,
    setCustomer: (customer: CustomerListProfile) => void,
    size?: FieldSize,
    autoSelectFirst?: boolean,
  },
): ReactElement => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const customersResponse: StatefulResponse<CustomerListProfile[]> = useCustomerList(searchKeyword);
  const [customers, setCustomers] = useState<CustomerListProfile[]>([]);
  Logger.log(`CustomerSelectSearch() defaultCustomer ${JSON.stringify(defaultCustomer)}`);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerListProfile | null>(
    defaultCustomer || null,
  );
  // Is default customer set?
  const [isDefaultSet, setIsDefaultSet] = useState(false);

  useAutoSelectFirst(autoSelectFirst, customers, setSelectedCustomer, defaultCustomer);

  useEffect(() => {
    // Set default customer
    if (!isDefaultSet && defaultCustomer) {
      customers.forEach((customer) => {
        if (customer.customerId === defaultCustomer?.customerId) {
          setSelectedCustomer(customer);
          setIsDefaultSet(true);
        }
      });
    }
  }, [defaultCustomer, customers, isDefaultSet, setIsDefaultSet, setSelectedCustomer]);

  useEffect(() => {
    if (!customersResponse.isLoading && customersResponse.response) {
      setCustomers(customersResponse.response.data);
    }
  }, [customersResponse]);

  useEffect(() => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
    }
  }, [selectedCustomer]);

  const getApiErrors = () => errors?.getErrors() || {};
  const hasApiErrors = () => Object.prototype.hasOwnProperty.call(getApiErrors(), 'customerId');
  const isCardMissing = (): boolean => selectedCustomer !== null && !selectedCustomer.hasCreditCard;
  const isCardPaymentMethod = ():
    boolean => selectedCustomer !== null
    && selectedCustomer?.paymentMethod === PaymentMethod.CARD;
  const showCardError = (): boolean => isCardMissing() && isCardPaymentMethod();

  const getErrors = (): string => {
    if (hasApiErrors()) {
      return getApiErrors().customerId;
    }
    if (showCardError()) {
      return 'Credit card missing';
    }
    return '';
  };

  return (
    <S.SelectFormControl
      variant="outlined"
    >
      {/* <InputLabel id="customers-select-label">Customer</InputLabel> */}
      <Autocomplete<CustomerListProfile>
        id="customers-select-search"
        style={{ width: 250 }}
        options={customers}
        getOptionLabel={(option: CustomerListProfile) => option.email}
        loading={customersResponse.isLoading}
        value={selectedCustomer}
        onChange={(event: any, customer: CustomerListProfile | null | undefined) => {
          if (customer) {
            setSelectedCustomer(customer);
          }
        }}
        inputValue={searchKeyword}
        onInputChange={(event, newInputValue) => {
          setSearchKeyword(newInputValue);
        }}
        renderInput={(params) =>

          /* eslint-disable react/jsx-props-no-spreading,implicit-arrow-linebreak */
          (
            <TextField
              {...params}
              variant="outlined"
              label="Customer"
              error={hasApiErrors() || showCardError()}
              helperText={getErrors()}
              size={size}
            />
          )}
        /* eslint-enable react/jsx-props-no-spreading,implicit-arrow-linebreak */
      />
    </S.SelectFormControl>
  );
};

CustomerSelectSearch.defaultProps = {
  defaultCustomer: null,
  errors: null,
  size: 'medium',
  autoSelectFirst: false,
};
