import React, { ReactElement, useCallback, useState } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { Logger } from '../../../shared/utils';
import { DialogStatus } from '../../core/dialog/dialog-slice';
import { useInitDialog, useUpdateDialogStatus } from '../../core/dialog/use-dialog';
import { StatefulDialog } from '../../core/dialog';
import * as S from './styles';
import { useCreateBudget, useCreateBudgetMutation, useCustomerBudget } from '../data';
import { CustomerBudgetDialogDescription } from './customer-budget-dialog-description';
import { useGetCustomerDetailQuery } from '../../dashboard/data';

export const BUDGET_DIALOG_TAG = 'budget-dialog';

export interface CustomerBudgetDialogArgs {
  customerId: string
}

export const CustomerBudgetDialog = ({ customerId }: CustomerBudgetDialogArgs): ReactElement => {
  const [budget, setBudget] = useState<number>(0);
  Logger.log(`BudgetDialog() ${budget}`);
  const [createBudget, result] = useCreateBudgetMutation();

  // Loading data
  const customerRes = useGetCustomerDetailQuery(customerId);
  const [previousBudget, budgetsRes] = useCustomerBudget(customerId, -1);
  const isLoading = useCallback(
    () => customerRes.isLoading || budgetsRes.isLoading,
    [customerRes, budgetsRes],
  );

  const budgetDialogDetails = {
    tag: BUDGET_DIALOG_TAG,
    isOpen: true,
    status: DialogStatus.INITIAL,
    title: 'Update Customer Budget',
    loadingText: 'Updating budget...',
    errorText: 'Updating budget failed. Please try again later.',
    successText: 'Budget updated successfully',
    positiveActionText: 'Save',
    negativeActionText: 'Later',
  };

  useInitDialog(budgetDialogDetails, BUDGET_DIALOG_TAG);
  useUpdateDialogStatus(result);
  useCreateBudget(customerId, budget, createBudget);

  const onBudgetValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBudget = parseInt(event.target.value, 10);
    setBudget(newBudget);
  };

  const content = isLoading() ? <CircularProgress />
    : (
      <>
        <CustomerBudgetDialogDescription
          customer={customerRes.data}
          previousBudget={previousBudget}
        />
        <S.BudgetFieldContainer>
          <TextField
            label="Budget"
            type="number"
            onChange={onBudgetValueChange}
          />
        </S.BudgetFieldContainer>
      </>
    );

  return (
    <StatefulDialog>
      {content}
    </StatefulDialog>
  );
};
