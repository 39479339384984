import React, { ReactElement } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import * as S from './customer-invoice-list.styles';
import { CustomerInvoiceListItem } from './customer-invoice-list-item';
import { CustomerInvoiceListHeader } from './customer-invoice-list-header';
import { Invoice, useGetCustomerInvoicesQuery } from '../data';
import { useAppSelector } from '../../../app/hooks';
import { selectDashboardCustomerId } from '../customer-dashboard/dashboard-customer-slice';

export const CustomerInvoiceList: React.FC = (): ReactElement => {
  const customerId = useAppSelector(selectDashboardCustomerId) ?? '';
  const res = useGetCustomerInvoicesQuery(customerId);
  const { data } = res;

  // We want to show empty list while loading another customer
  const invoices = res.isFetching ? []
    : data?.map((invoice) => new Invoice(invoice)) || [];

  return (
    <S.GridInvoicesSection>
      <S.Container>
        <S.SectionHeader>Invoices</S.SectionHeader>
        <AutoSizer>
          {({ width = 0 }) => (
            <>
              <CustomerInvoiceListHeader listWidth={width} />
              <FixedSizeList<Invoice[]>
                itemSize={48}
                height={245}
                width={width}
                itemCount={invoices.length}
                overscanCount={5}
                itemData={invoices}
              >
                {CustomerInvoiceListItem}
              </FixedSizeList>
            </>
          )}
        </AutoSizer>
      </S.Container>
    </S.GridInvoicesSection>
  );
};
