import { ApiMethod, ApiObjectParser, ApiService } from '../../api-legacy';
import { GaaslyResponse } from '../../../shared/models';
import { getAuthToken } from '../../auth/data';
import { HolidayStats, TimeStats } from './types';

const API_URL = process.env.REACT_APP_API_URL;
const GROWTH_PARTNERS_URL = `${API_URL}/api/growth-partners/`;

const getGPHolidaysUrl = (growthPartnerId: string): string => `${GROWTH_PARTNERS_URL}${growthPartnerId}/holidays/`;

const getGPStatsUrl = (
  growthPartnerId: string,
  startDate: string,
  endDate: string,
): string => `
${GROWTH_PARTNERS_URL}${growthPartnerId}/stats/?start_date=${startDate}&end_date=${endDate}
`;
/**
 * @param growthPartnerFilter "all", "front-office" or "growthPartnerId"
 */
export const fetchHolidays = (
  growthPartnerFilter: string,
): Promise<GaaslyResponse<HolidayStats>> => {
  const url = getGPHolidaysUrl(growthPartnerFilter);
  return new ApiService<HolidayStats>(url)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser())
    .request();
};

/**
 * @param growthPartnerFilter "all", "front-office" or "growthPartnerId"
 * @param startDate: date in string format "YYYY-MM-DD
 * @param endDate: date in string format "YYYY-MM-DD
 */
export function fetchGrowthPartnerStats(
  growthPartnerFilter: string,
  startDate: string,
  endDate: string,
): Promise<GaaslyResponse<TimeStats>> {
  const url = getGPStatsUrl(growthPartnerFilter, startDate, endDate);
  return new ApiService<TimeStats>(url)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser())
    .request();
}
