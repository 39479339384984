import { apiSlice, PaginatedResponse } from '../../api';
import { TimeEntry } from '../../time-tracking/data';
import { CustomerDetailProfile } from '../../customers';
import { ApiInvoice, CustomerStats } from './types';

const getCustomerTimeEntriesUrl = (
  { customerId, startDate, endDate }: { customerId: string, startDate: string, endDate: string },
): string => {
  let url = `api/customers/${customerId}/time-entries/`;
  if (startDate && endDate) {
    url = `api/customers/${customerId}/time-entries`
      .concat(`?start_date=${startDate}`)
      .concat(`&end_date=${endDate}`);
  }
  return url;
};

interface GetCustomerTimeEntriesArgs {
  customerId: string;
  startDate?: string;
  endDate?: string;
}

const customerAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // TODO dashboard replace CustomerDetailArgs with string
    getCustomerDetail:
      builder.query <CustomerDetailProfile, string>({
        query: (customerId) => ({
          url: `api/customers/${customerId}/`,
          method: 'GET',
        }),
      }),
    getCustomerStats: builder.query<CustomerStats[], string>({
      query: (customerId) => ({
        url: `api/customers/${customerId}/stats/`,
        method: 'GET',
      }),
    }),
    getCustomerTimeEntries:
      builder.query<PaginatedResponse<TimeEntry[]>, GetCustomerTimeEntriesArgs>({
        query: ({ customerId, startDate, endDate }) => ({
          url: `/api/customers/${customerId}/time-entries/`,
        }),
      }),
    // TODO dashboard replace CustomerDetailArgs with string
    getCustomerInvoices: builder.query<ApiInvoice[], string>({
      query: (customerId) => ({
        url: `api/customers/${customerId}/invoices/`,
      }),
    }),
    // TODO dashboard replace CustomerDetailArgs with string
    checkoutCustomer: builder.mutation<void, string>({
      query: (customerId) => ({
        url: `api/customers/${customerId}/checkout/`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetCustomerDetailQuery,
  useGetCustomerStatsQuery,
  useGetCustomerTimeEntriesQuery,
  useGetCustomerInvoicesQuery,
  useCheckoutCustomerMutation,
} = customerAPISlice;
