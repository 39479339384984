import { roundToTwoDecimals } from '../../../shared/utils';
import { TimeStats } from './index';
import { DialogClosePayload, DialogResult } from '../../core/dialog/dialog-slice';
import { SEND_INVOICE_DIALOG_TAG } from '../customer-dashboard-checkout/send-invoice-dialog';
import {
  CHARGE_CREDIT_CARD_DIALOG_TAG,
} from '../customer-dashboard-checkout/charge-credit-card-dialog';
import { BUDGET_DIALOG_TAG } from '../../customer-budget/ui/customer-budget-dialog';

export const getInvoicedValue = (timeStats: TimeStats | undefined): string => {
  const { internalHours = 0, invoicedHours = 0 } = timeStats || {};
  const hours = internalHours + invoicedHours;
  if (hours === 0) {
    return '0h';
  }
  const percentage = Math.round((invoicedHours / (hours)) * 100);
  return `${roundToTwoDecimals(invoicedHours)}h (${roundToTwoDecimals(percentage)}%)`;
};

export const getInternalValue = (timeStats: TimeStats | undefined): string => {
  const { internalHours = 0, invoicedHours = 0 } = timeStats || {};
  const hours = internalHours + invoicedHours;
  if (hours === 0) {
    return '0h';
  }
  const percentage = Math.round((internalHours / (hours)) * 100);
  return `${roundToTwoDecimals(internalHours)}h (${roundToTwoDecimals(percentage)}%)`;
};

export const getHolidaysCount = (timeStats: TimeStats | undefined): string => {
  const { holidayHours = 0 } = timeStats || {};
  const holidays = holidayHours / 7.5;
  return `${roundToTwoDecimals(holidays)}d`;
};

export const getTotalValue = (timeStats: TimeStats | undefined): string => {
  const { allHours = 0 } = timeStats || {};
  return `${roundToTwoDecimals(allHours)}h`;
};

export const getRevenueValue = (timeStats: TimeStats | undefined): string => {
  const { revenue = 0 } = timeStats || {};
  return `${roundToTwoDecimals(revenue)}€`;
};

export const getAverageHourlyPriceValue = (timeStats: TimeStats | undefined): string => {
  const { averageHourlyPrice = 0 } = timeStats || {};
  return `${roundToTwoDecimals(averageHourlyPrice)}€`;
};

export const getFteValue = (timeStats: TimeStats | undefined): string => {
  const { allHours } = timeStats || {} as TimeStats;
  const fte = allHours / 157.5;
  return `${roundToTwoDecimals(fte)}`;
};

export const getTimeBalanceValue = (timeStats: TimeStats | undefined): string => {
  if (timeStats?.timeBalance) {
    const { timeBalance } = timeStats;
    const operator = timeBalance >= 0 ? '+' : '';
    return `${operator}${roundToTwoDecimals(timeBalance)}h`;
  }
  return '';
};

// TODO dashboard, move closer to the code using this...
export const isCustomerRefresh = (payload: DialogClosePayload) => (
  payload.result === DialogResult.SUCCESS
  && (
    payload.tag === SEND_INVOICE_DIALOG_TAG
    || payload.tag === CHARGE_CREDIT_CARD_DIALOG_TAG
    || payload.tag === BUDGET_DIALOG_TAG
  ));
