import React, { ReactElement } from 'react';
import * as S from './billing-info.styles';
import { Row } from './row';
import { MultiRowBox } from './multi-row-box';
import { isoCountryList } from '../../country-select/data';
import { useAppSelector } from '../../../app/hooks';
import { selectDashboardCustomer } from '../customer-dashboard/dashboard-customer-slice';
import { placeholderCustomer } from '../../customers';

export const BillingInfo = (): ReactElement => {
  const customer = useAppSelector(selectDashboardCustomer);
  const {
    firstName, lastName, email, companyName, invoicingEmail, addressLine1, addressLine2,
    city, postalCode, country, additionalBillingInfo, vatId,
  } = customer ?? placeholderCustomer;

  const countryName = isoCountryList.find((c) => c.code === country)?.name ?? '';

  return (
    <S.GridInfoSection>
      <S.Container>
        <S.SectionHeaderH1>{companyName}</S.SectionHeaderH1>
        <S.TextLight>{`${firstName} ${lastName} - ${email}`}</S.TextLight>
        <S.SectionHeaderH2>
          Billing Information
        </S.SectionHeaderH2>
        <S.InnerContainer elevation={0}>
          <Row field="Invoicing Email:" value={invoicingEmail} />
          <Row field="Company Name:" value={companyName} />
          <Row field="Address Line 1:" value={addressLine1} />
          <Row field="Address Line 2:" value={addressLine2} />
          <Row field="City:" value={city} />
          <Row field="Postal Code:" value={postalCode} />
          <Row field="Country:" value={countryName} />
          <Row field="European VAT ID:" value={vatId} />
          <MultiRowBox field="Additional Billing Information:" value={additionalBillingInfo} />
        </S.InnerContainer>
      </S.Container>
    </S.GridInfoSection>
  );
};
