import { useEffect } from 'react';
import { addListener } from '@reduxjs/toolkit';
import { Logger } from '../../../shared/utils';
import { dialogClose, DialogClosePayload, DialogResult } from '../../core/dialog/dialog-slice';
import { SEND_INVOICE_DIALOG_TAG } from '../customer-dashboard-checkout/send-invoice-dialog';
import {
  CHARGE_CREDIT_CARD_DIALOG_TAG,
} from '../customer-dashboard-checkout/charge-credit-card-dialog';
import { BUDGET_DIALOG_TAG } from '../../customer-budget/ui/customer-budget-dialog';
import { useAppDispatch } from '../../../app/hooks';

export const useRefresh = (): void => {
  const dispatch = useAppDispatch();
  Logger.log('useRefresh()');

  const isRefreshNeeded = (payload: DialogClosePayload) => (
    payload.result === DialogResult.SUCCESS
    && (
      payload.tag === SEND_INVOICE_DIALOG_TAG
      || payload.tag === CHARGE_CREDIT_CARD_DIALOG_TAG
      || payload.tag === BUDGET_DIALOG_TAG
    ));

  useEffect(() => {
    const unsubscribe = dispatch(addListener({
      actionCreator: dialogClose,
      effect: (action, listenerApi) => {
        if (isRefreshNeeded(action.payload)) {
          window.location.reload();
        }
      },
    }));
    return unsubscribe;
  }, []);
};
