import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
  selectDashboardCustomerId,
} from '../../dashboard/customer-dashboard/dashboard-customer-slice';
import { CustomerBudgetDialog } from './customer-budget-dialog';

export const DashboardCustomerBudgetDialog = (): ReactElement => {
  const customerId = useAppSelector(selectDashboardCustomerId) ?? '';
  return <CustomerBudgetDialog customerId={customerId} />;
};
