import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerDetailQuery } from '../data';
import { useAppDispatch } from '../../../app/hooks';
import { dashboardCustomerChange } from './dashboard-customer-slice';

export const useCustomer = (): [
  ReturnType<typeof useGetCustomerDetailQuery>,
  (customerId: string) => void,
] => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const { customerId } = JSON.parse(JSON.stringify(params));
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>(customerId);
  const customerRes = useGetCustomerDetailQuery(selectedCustomerId);

  useEffect(() => {
    if (customerRes.isFetching) {
      dispatch(dashboardCustomerChange(undefined));
    } else {
      dispatch(dashboardCustomerChange(customerRes.data));
    }
  }, [customerRes]);

  return [customerRes, setSelectedCustomerId];
};
