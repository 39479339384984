import React, { ReactElement, useState } from 'react';
import * as S from './styles';
import { GrowthPartnerHolidaysSection, GrowthPartnerStatsSection } from './index';
import { GrowthPartnerProfile } from '../../../shared/models';
import { GrowthPartnerSearchSelect } from '../../growth-partner-select';
import { useAuthUser } from '../../auth';

export const GrowthPartnerDashboard = (): ReactElement => {
  const authGrowthPartner = useAuthUser() as GrowthPartnerProfile;
  const [growthPartnerFilter, setGrowthPartnerFilter] = useState<
    string>(authGrowthPartner.growthPartnerId);

  const onGrowthPartnerFilterChange = (selectedFilter: string) => {
    setGrowthPartnerFilter(selectedFilter);
  };

  return (
    <S.DashboardPage>
      <S.DashboardTop>
        <S.GrowthPartnerSearchBackground>
          <GrowthPartnerSearchSelect
            defaultGrowthPartner={authGrowthPartner}
            setGrowthPartnerFilter={onGrowthPartnerFilterChange}
          />
        </S.GrowthPartnerSearchBackground>
      </S.DashboardTop>

      <GrowthPartnerStatsSection growthPartnerFilter={growthPartnerFilter} />
      <GrowthPartnerHolidaysSection growthPartnerFilter={growthPartnerFilter} />
    </S.DashboardPage>
  );
};
